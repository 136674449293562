// Boxed contents

.bg--maincol,
.boxed.bg--maincol,
.boxed.boxed--border.bg-maincol {
    background-color: color(primary);
}

.bg--secondarycol,
.boxed.bg--secondarycol,
.boxed.boxed--border.bg-secondarycol {
    background-color: color(dark);
}

.boxed {
    padding: $padding-big;

    &.boxed--rad-1 {
        border-radius: 2px !important;
    }

    &.boxed--rad-2 {
        border-radius: 4px !important;
    }

    &.boxed--rad-3 {
        border-radius: 6px !important;
    }

    &.boxed-home-page-form {
        padding: 46px;
    }
    &.boxed--compact {
        padding: $padding-small;
        border-radius: 2px;
    }

    &.boxed--shadow {
        -webkit-box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
        box-shadow: 0 0 20px rgba(2, 32, 33, 0.05);
    }

    &.boxed--border {
        &.border--main {
            border: 1px solid color(primary);
        }
        &.border--secondary {
            border: 1px solid color(dark-accent);
        }

        &.border--light {
            border: 1px solid rgba($color: color(dark-accent), $alpha: 0.2) !important;
        }
    }

    .boxed__text {
        align-self: center;
    }

    .boxed__title {
        font-size: $textsize-title;
        font-weight: 800;
        color: color(dark);
        line-height: 120%;

        &.kisfaludybar-title {
            display: block;
            margin-left: 40px;
        }
        &[class*="--medium"] {
            font-size: $textsize-title-medium;
        }
    }

    .boxed__bodytext {
        line-height: 160%;

        &:not(:last-of-type) {
            margin-bottom: $padding-big;
        }

        > p {
            margin-bottom: 1em;
            line-height: inherit;
        }
    }

    .boxed__pic-container {
        align-self: stretch;

        .boxed__pic {
            height: 100%;
            width: 100%;
            display: block;
            position: relative;

            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        .boxed__pic__modal {
            height: 100%;
            width: 100%;
            display: block;
            position: relative;

            &.center-align-home-form {
                align-items: center;
                display: flex;
            }
            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .btn {
        margin-top: 12px;
    }

    .boxed-content {
        &.no-padding {
            padding: 0 !important;
        }
        //Grid Tables
        .boxed-table {
            display: grid;
            width: 100%;
        }
    }
}

/*BOXED STYLES*/

.boxed {
    &.boxed--nopadding-all {
        padding: 0;
        margin: 0;
    }
    &.boxed--nopadding {
        .boxed-content {
            padding: 0;
            margin: 0;
        }
    }
    &.boxed--style1 {
        padding: 0;
        background-color: white;

        .box-header {
            text-align: center;
            display: block;
            width: 100%;
            color: white;
            border-bottom: 5px solid color(primary);
            padding: 1em 2.5em;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding: 0 !important;
                margin: 0 !important;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            h1,
            h2 {
                letter-spacing: 2px;
            }

            h3 {
                font-weight: 600;
            }

            .header-content {
                padding: 1em 2.5em;
            }

            .box-content-section {
                &:not(:last-of-type) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                }

                div {
                    padding-right: 25px;
                    padding-left: 25px;
                }

                .box-section-header {
                    font-size: 1.65em;
                    background-color: rgba(0, 0, 0, 0.045);
                    padding-top: 0.65em;
                    padding-bottom: 0.65em;
                }
            }
        }

        .box-header--copy2 {
            text-align: center;
            display: block;
            width: 100%;
            color: white;
            border-bottom: 5px solid color(primary);
            padding: 0.5em;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding: 0 !important;
                margin: 0 !important;
                color: color(primary);
            }

            h3 {
                font-weight: 200;
            }

            .header-content {
                padding: 1em 2.5em;
            }

            .box-content-section {
                &:not(:last-of-type) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                }

                div {
                    padding-right: 25px;
                    padding-left: 25px;
                }

                .box-section-header {
                    font-size: 1.65em;
                    background-color: rgba(0, 0, 0, 0.045);
                    padding-top: 0.65em;
                    padding-bottom: 0.65em;
                }
            }
        }

        .pd-bottom {
            padding-bottom: 20px;
        }
        .box-header--copy-left {
            text-align: left;
        }
        .box-header--copy {
            text-align: center;
            display: block;
            width: 100%;
            color: white;
            border-bottom: 5px solid color(primary);
            padding: 1em 2em;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding: 0 !important;
                margin: 0 !important;
                color: color(primary);
            }

            h3 {
                font-weight: 600;
            }

            .header-content {
                padding: 1em 2.5em;
            }

            .box-content-section {
                &:not(:last-of-type) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                }

                div {
                    padding-right: 25px;
                    padding-left: 25px;
                }

                .box-section-header {
                    font-size: 1.65em;
                    background-color: rgba(0, 0, 0, 0.045);
                    padding-top: 0.65em;
                    padding-bottom: 0.65em;
                }
            }
        }

        .boxed-content {
            padding: 25px;

            &.no-top-bottom {
                padding: 0 25px 0px 25px;
                &.no-sides-padding {
                    padding: 0;
                }
            }

            &.no-bottom {
                padding: 25px 25px 0 25px;
            }

            @include mqLargeScreen {
                padding: 32px;

                &.no-top-bottom {
                    padding: 0 32px 0 32px;
                    &.no-sides-padding {
                        padding: 0;
                    }
                }

                &.no-bottom {
                    padding: 32px 32px 0 32px;
                }
            }
        }
    }

    &.boxed--style-info {
        margin: 25px 0 25px 0 !important;
        background-color: color(primary) !important;
        min-height: 1px;
        padding: 10px;
        line-height: 20px;
        font-size: 13px;
        border: 1px solid color(dark);
        font-weight: 500;
        .info-text-wrapper {
            margin-right: 20px;
        }
        .info-section {
            margin: auto 0 !important;
            padding: 0 20px !important;
        }
        .style-info-wrapper {
            display: flex;
        }
        i {
            color: color(dark);
        }
        span {
            color: color(dark);
        }
    }

    /*Checkout page Selected Tickets*/
    &.boxed--selected-tickets {
        .selected-tickets__table {
            .ticket-type-col {
                text-align: left;

                @include mqTablet {
                    width: 65%;
                }
            }
            .ticket-price-col {
                text-align: left;

                @include mqTablet {
                    text-align: center;
                    width: 35%;
                    min-width: 185px;
                }
            }
            tbody {
                .selected-tickets__type {
                    .type__title,
                    .type__date,
                    .type__list {
                        display: block;
                    }

                    .type__title {
                        font-weight: 700;
                    }

                    .type__date {
                        font-size: 13px;
                    }
                    .type__list {
                        margin-top: 4px;
                    }
                }

                .selected-tickets__price {
                    font-weight: 700;
                }
            }

            tfoot {
                tr > td {
                    text-align: left;

                    @include mqTablet {
                        text-align: right;
                    }
                }
            }
        }
    }

    /*Single Cart List Box*/
    &.boxed--cartlist {
        .boxed-content {
            .box-table {
                tbody > tr > td {
                    text-align: center;
                }
                .cartlist__ticket {
                    line-height: 0;
                    text-align: left;

                    .ticket__type {
                        font-size: $textsize-body;
                        font-weight: bold;
                        line-height: normal;
                        padding: 0;
                        margin: 0;
                    }

                    .ticket__date {
                        font-size: 13px;
                        line-height: normal;
                    }

                    .ticket__items {
                        padding-top: 4px;

                        > li {
                            display: flex;
                            align-items: center;
                            margin: 0;
                            padding: 0;
                            font-size: $textsize-body;
                            line-height: normal;

                            @include mqSmallScreen {
                                &::before {
                                    content: "";
                                    background-color: black;
                                    width: 12%;
                                    height: 1px;
                                    max-width: 40px;
                                    min-width: 16px;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*Cart Sum Box*/
    &.boxed--cart-sum {
        width: 100%;

        .box-header {
            border-bottom: 1px solid color(dark);

            .box-header__container {
                display: block;
                padding: 12px $padding-medium;

                @include mqTablet {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .box-header__box-title-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 12px 0;

                    .box-title__icon {
                        display: block;
                        margin-right: $padding-tiny;

                        > svg {
                            display: block;
                            height: 22px;
                            width: 22px;
                        }
                    }

                    .box-title__title > h2 {
                        font-size: $textsize-lead;
                        font-weight: 700;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            .box-header__buttons-wrapper {
                margin-top: 12px;

                @include mqTablet {
                    margin-top: 0;
                }

                a {
                    &:not(:last-of-type) {
                        display: block;
                        margin-bottom: 6px;

                        @include mqTablet {
                            display: initial;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }

        .boxed-content {
            padding: $padding-medium;

            .box-content__ticketsum-table-container {
                .ticketsum-table-wrapper {
                    margin-top: 12px;
                    padding: 0;

                    @include mqSmallScreen {
                        margin-top: 0;
                    }
                    .ticketsum-table {
                        width: 100%;
                        table-layout: fixed;
                        border-collapse: collapse;
                        font-size: 16px;

                        thead {
                            tr > td {
                                font-size: $textsize-compact;
                                font-weight: 700;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                text-align: center;
                                margin-top: 0;
                                padding-top: 0;
                                padding-left: 0;
                                padding-right: 0;
                            }

                            .ticketsum__ticket-type-col {
                                width: 40%;
                                text-align: left;
                            }

                            .ticketsum__ticket-quantity-col {
                                width: 20%;
                            }
                        }

                        tbody {
                            tr > td {
                                font-size: 16px;
                                text-align: center;
                                padding-left: 0;
                                padding-right: 0;
                                padding-top: 6px;
                                padding-bottom: 6px;

                                @include mqTablet {
                                    padding: 16px 0;
                                }
                            }

                            tr {
                                &:not(:last-of-type) {
                                    border-bottom: 1px solid color(light);
                                }
                                .ticketsum__ticket-item {
                                    text-align: left;

                                    > span {
                                        display: block;
                                    }
                                    .ticket-item__title {
                                        font-weight: 700;
                                    }

                                    .ticket-item__date {
                                        font-size: 13px;
                                    }

                                    .ticket-item__item-list {
                                        list-style-type: none;
                                        list-style: none;
                                        padding: 0;
                                        margin-top: 2px;

                                        > li {
                                            display: inline;
                                            position: relative;
                                            vertical-align: middle;
                                            line-height: 0;

                                            &::before {
                                                display: none;
                                                vertical-align: middle;
                                                height: 1px;
                                                width: 45px;
                                                background-color: color(dark);
                                                position: absolute;
                                                top: 50%;
                                                left: 0;

                                                transform: translateY(-50%);
                                            }

                                            @include mqLargeScreen {
                                                display: inline-block;
                                                padding-left: 50px;
                                                &::before {
                                                    display: inline-block;
                                                    content: "";
                                                }
                                            }
                                        }
                                    }
                                }

                                .ticketsum-item__price {
                                }

                                .ticketsum-item__quantity {
                                    text-align: left;
                                    @include mqTablet {
                                        text-align: center;
                                    }
                                }

                                .ticketsum-item__sum {
                                    text-align: left;

                                    @include mqTablet {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .box-footer {
            width: 100%;
            border-top: 1px solid color(dark);
            background-color: color(light);

            .box-footer__container {
                padding: 12px $padding-medium;

                .box-footer__sum-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    > span {
                        font-size: $textsize-lead;

                        &.sum__text {
                            font-weight: 400;
                            text-transform: uppercase;
                            margin-right: 32px;
                        }

                        &.sum__num {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    /*Ticket Date box*/
    &.boxed--ticket-calendar {
        min-width: 320px;
        width: 100%;

        .box-header {
            display: flex;
            justify-content: center;
            align-items: center;

            .box-header__month {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-content: center;

                > span {
                    font-size: 22px;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin: 0 32px;
                }

                > a {
                    font-size: 13px;
                    font-weight: normal;
                    display: inline-block;
                    align-self: center;

                    > img {
                        height: 12px;
                        display: block;
                    }

                    &.arrow--back {
                        transform: rotate(180deg);
                    }
                }
            }

            .box-header__year {
                position: absolute;
                left: 25px;
            }
        }

        .boxed-content {
            padding: 0;
            .box-content__table {
                table-layout: fixed;
                border-collapse: collapse;
                border-style: transparent;

                td {
                    font-size: 13px;
                    border: 1px solid color(light);
                }

                tr {
                    &:first-child {
                        td {
                            border-top: 0;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: 0;
                        }
                    }

                    td {
                        cursor: default;
                        &:hover {
                            outline: 2px solid rgba($color: color(dark), $alpha: 0.9);
                            outline-offset: -2px;
                            opacity: 1 !important;
                        }
                        &:first-child {
                            border-left: 0;
                        }

                        &:last-child {
                            border-right: 0;
                        }
                    }
                }

                .date-picker-table {
                    thead {
                        tr {
                            td {
                                text-align: center;
                                border-bottom: 1px solid color(light);
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                height: 65px;
                                width: 65px;
                                font-size: 18px;
                                font-weight: 600;
                                text-align: right;
                                vertical-align: bottom;
                                padding: 8px;

                                &.prev-month,
                                &.next-month {
                                    font-weight: normal;
                                    opacity: 0.45;
                                }

                                &.status-1 {
                                    background-color: rgba($color: color(light), $alpha: 0.65);
                                    border-color: color(lighter);
                                    color: rgba($color: #000000, $alpha: 0.35);
                                }

                                &.status-2 {
                                    background-color: color(red);
                                    border-color: white;
                                    color: white;
                                }

                                &.status-3 {
                                    background-color: color(primary);
                                    border-color: white;
                                }

                                &.focused {
                                    border: 4px solid color(dark);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*Ticket type boxes*/
    &.boxed--cruise-type {
        margin-bottom: 0;

        .box-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 1em 32px;

            @include mqSmallScreen {
                flex-direction: row;
            }

            .box-header__left-col,
            .box-header__right-col {
                display: flex;
                flex-direction: column;
                align-items: center;

                @include mqSmallScreen {
                    flex-direction: row;
                }
            }

            .box-header__left-col {
                @include mqSmallScreen {
                    padding-right: 12px;
                }
            }

            .box-header__right-col {
            }

            .cruise-type__title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-bottom: 8px;
                text-align: left;

                @include mqSmallScreen {
                    margin-bottom: 0;
                }

                img {
                    margin-right: 12px;
                }
            }

            .cruise-type__description {
                font-weight: 200;
                text-align: center;

                @include mqSmallScreen {
                    border-left: 2px solid color(dark-accent);
                    margin-left: 24px;
                    padding-left: 24px;
                    text-align: left;
                }

                b {
                    font-weight: 600;
                }
            }

            .cruise-type__timetable {
                display: inline-block;
                position: relative;
                text-align: right;

                // @include mqSmallScreen {
                //     position: absolute;
                //     right: 42px;
                // }

                > a {
                    display: flex;
                    align-items: center;
                    font-weight: 200;
                    text-transform: uppercase;
                    color: color(primary) !important;
                    text-decoration: none;
                    margin-top: 12px;

                    @include mqSmallScreen {
                        margin-top: 0;
                    }

                    > svg {
                        margin-right: 6px;
                        width: auto;
                        height: 20px;
                        display: block;
                    }
                }
            }
        }

        .boxed-content {
            padding: 0;
            display: block;
            overflow-x: auto;
            white-space: nowrap;

            .boxed-content__table {
                width: 100%;

                tr,
                td {
                    padding: 2px;

                    @include mqSmallScreen {
                        padding: 0 32px;
                    }
                }

                td {
                    &.table__ticket-type-col {
                        text-align: center;

                        @include mqSmallScreen {
                            text-align: left;
                        }
                    }
                }

                thead {
                    tr {
                        &.table__head {
                            td {
                                padding: 12px 32px;
                                font-size: 18px;

                                &:not(:last-of-type) {
                                    border-right: 1px solid color(light);
                                }
                            }
                        }
                    }
                }

                tbody {
                    width: 100%;

                    &:hover {
                        > tr {
                            background-color: white !important;

                            &:hover {
                                background-color: rgba($color: color(light), $alpha: 0.75) !important;
                            }
                        }
                    }
                    tr {
                        border-bottom: none;
                        height: 70px;
                        padding: 12px;
                        transition: background-color 0.15s ease-in-out;

                        // &:hover{
                        //     background-color: rgba($color: color(primary), $alpha: .25) !important;
                        // }

                        &:nth-child(odd) {
                            background-color: rgba($color: color(light), $alpha: 0.45);
                        }

                        @include mqTablet {
                            padding: unset;
                        }

                        td {
                         
                            text-align: center;

                            &:not(:last-of-type){
                                border-right: 1px solid color(light);
                            }

                            &.table-cell__title {
                                font-size: 18px;
                                font-weight: 700;
                                width: 50%;
                            }
                            &.table-cell__price {
                                font-size: 18px;
                                font-weight: 700;
                                width: 30%;

                                @include mqSmallScreen {
                                    text-align: center;
                                }
                            }

                            &.table-cell__btn {
                                text-align: center;
                            }

                            .table-cell__note {
                                display: block;
                                font-size: 13px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* BOXED TABLES */
.box-table-wrapper {
    .box-table {
        width: 100%;
        padding: 0;
        margin: 0;
        min-height: 1px;

        tr > td {
            padding: 12px 40px;
            margin: 0;
        }

        // Table column dimensions
        td {
            &.table-col-40 {
                width: 40%;
            }
        }

        &.box-table--simple {
            thead {
                tr > td {
                    font-size: $textsize-compact;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 1px;
                    text-align: center;

                    &:not(:last-of-type) {
                        border-right: 1px solid color(light);
                    }
                }

                tr {
                    border-bottom: 1px solid color(light);
                }
            }

            tbody {
                tr {
                    &:not(:last-of-type) {
                        border-bottom: 1px solid color(light);
                    }
                }
                tr > td {
                    &:not(:last-of-type) {
                        border-right: 1px solid color(light);
                    }
                }
                .box-table__list {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                }
            }

            tfoot {
                border-top: 1px solid black;
            }
        }
    }
}

.max-width-test {
    width: 20%;
    @include mqTablet {
        width: 28%;
    }
    &.ship-types {
        width: 100%;
        margin: 35px 0;
    }
}

.left-radius-test {
    border-top-left-radius: 0px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.right-radius-and-right-border {
    width: 70%;
    border-right: 1px solid #ececec;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    @include mqTablet {
        width: 44%;
    }
}

.center-row-right-border {
    border-right: 1px solid #ececec;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

.both-radius-test {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    overflow: unset;
}

.first-row {
    height: 150px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mqTablet {
        height: 70px !important;
        padding: 0 10px !important;
    }
}

.all-row {
    height: 100px !important;

    img {
        align-items: flex-end;
    }

    @include mqTablet {
        height: 80px !important;
    }
}

.img-text-wrapper {
    display: flex;
    max-height: 100px;
}
