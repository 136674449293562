
.section--cruise-types{
    .section-content__wrapper{
        .cruise-types__item{

            &:not(:last-of-type){
                margin-bottom: 50px;
            }
            
        }
    }
}
    
    
    .schedule-timetable {


        .description-span{
        font-size:10px
        }
        > .schedule-notification {
            width: 100%;
            padding: 18px 18px !important;
            background-color: color(primary);

            > p {
                font-size: 16px;
                color: color(dark);
                display: block;
                position: relative;
                line-height: 140%;
                padding-left: 35px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 25px;
                    width: 25px;
                    background-image: url(../../img/icons/warn.svg);
                    background-size: contain;
                }
            }
        }

        > .schedule-timetable__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            width: 100%;
            padding: 20px 0 20px 0;
            height:70px;

            &.standard-padding-titles{
            
                @include mqTablet {
                    padding: 20px 0 20px 15px;
                }
            }
     
            .icons-class{
                color:color(dark);
                padding-left: 25px;
                padding-right: 10px;
                width:20%;
                display: flex;
    justify-content: center;


    
    &:before{
        border-radius: 50%;
        border: 2px solid black;
        padding: 6px;
    }

    &.info{
        &:before{
         
            border: none;
      
        }
    }
            }

            .icon-wrapper{
                width: 30px;
    justify-content: flex-end;
    height: 100%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
                .icons-class{
                  
                    padding-left: 0px;
                    padding-right: 0px;
                  
                    display: flex;
        justify-content: center;
        margin-bottom: auto;
   
        width: 30px;
   
        
        &:before{
            border-radius: 50%;
            border: 2px solid black;
            padding: 6px;
        }
    
        &.info{



            &:before{
             
                border: none;
          
            }
        }
            }
           
            }
            &.subtitle-table{
                display: flex;
                flex-direction: column;
            }
            &:nth-of-type(even) {
                background-color: rgba($color: color(lighter), $alpha: 0.45);
            }

            &:last-of-type {
              //  margin-bottom: 20px;
            }

            > .schedule__time {
                //width: 55%;
                width: 100%;
                
                display: block;
                text-align: center;
                font-size: $textsize-lead;
                font-weight: bolder;
                color: color(dark);

                &.text-orientation{
                text-align: center;
                font-weight: 800;
                font-size: 1.3rem;
    

                }

                &.text-orientation-wo-pd{
                    text-align: left;
                    width:80%;
                    

                    &.text-container-types{
                        height:100%;
                        width:90%;
                        flex-direction: column;
                        display: flex;
                        .subtext-types-wrapper{
                            position: relative;
    top: -2px;
                            line-height: 12px;
                        }
                        .subtext-types{
                        font-size:12px;
                        }
                        .subtext-types2{
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    }
            }

            > .special-route-icons {
                width: 45%;
                padding-left: 12px;
                height: 10px;
                line-height: 0;

                > i {
                    position: relative;
                    opacity: 100%;
                    display: block;
                    width: 42px;
                    height: 42px;
                    content: none;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: 42px;
                        height: 42px;
                        background-color: color(primary);
                        border-radius: 100%;
                        z-index: 0;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 28px;
                        height: 28px;
                        transform: translateX(-50%) translateY(-50%);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        z-index: 1;
                    }

                    &.wine-glass {
                        &::after {
                            background-image: url(../../img/icons/wine-glasses.svg);
                        }
                    }
                }
            }
        }

        // > .schedule-column {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     min-height: 135px;
        //     position: relative;
        //     border: 1px solid rgba($color: color(light), $alpha: 0.6);

        //     &.schedule-column--empty {
        //         overflow: hidden;
        //         &::before {
        //             content: "";
        //             position: absolute;
        //             top: 50%;
        //             left: -25%;
        //             display: block;
        //             transform: rotate(45deg);
        //             transform-origin: center;
        //             width: 150%;
        //             border-bottom: solid 2px rgba($color: color(light), $alpha: 0.6);
        //         }

        //         &::after {
        //             content: "";
        //             position: absolute;
        //             top: 50%;
        //             left: -25%;
        //             display: block;
        //             transform: rotate(-45deg);
        //             transform-origin: center;
        //             width: 150%;
        //             border-bottom: solid 2px rgba($color: color(light), $alpha: 0.6);
        //         }
        //     }
        // }
    }

    .schedule-timetable .schedule-header span {
        vertical-align: middle;
        padding-right: 5px;
    }

    .schedule-timetable .schedule-time {
        text-align: center;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        font-size: 1.65em;
        font-weight: 600;
        color: black;
    }

    /*schedule details / icons */

    .schedule-timetable .schedule-details {
        display: block;
        width: 100%;
        font-size: 0.8em;
        line-height: 120%;
        padding: 10px 15px;
        text-align: center;
    }

    .schedule-timetable .schedule-details span {
        display: inline-block;
        vertical-align: middle;
        opacity: 0.5;
    }

    .schedule-timetable .icon:not(:only-child) {
        padding-right: 5px;
    }

    .schedule-timetable .icon {
        height: 0.65em;
        opacity: 0.4;
    }

    .schedule-timetable .icon.pirate-flag,
    .schedule-timetable .icon.moon,
    .schedule-timetable .icon.sunset {
        height: 0.5em;
    }


.title-heights{
.tickets-title{
    font-size: 25px !important;
    font-weight: 600 !important;
    letter-spacing: 2px;
}
}
// Ticket box
.tickets-box {
    .boxed {
        padding-bottom: 5px;
    }
    .tickets__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 97px;

        &.title-heights{
        height:89px;
        }
        h3 {

            &.tickets-title{
                letter-spacing: 2px;
                font-size: 25px !important;
                font-weight: 600;
            }
            width: 100%;
        }
    }

    .tickets-block {
        width: 100%;

        .tickets-block__item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            padding: 20px 0;
             &.tickets-btn-container{
                border-top:0 !important;
                padding-top:0;
             }
            .info-wrapper{
display:flex;
flex-direction: row;
                .img-wrapper{
                width:60px;
                margin: 0 12px 12px 12px;
                }
                .info-title{
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    color:rgba(0, 0, 0, 0.87)
                }
                .img-info{
                width:20px;
                }

                .info-text{
                    font-weight: 600;
                    color:color(dark);
                    font-size: 12px;
                    margin-top:5px;
                }
            }
            &:not(:first-of-type) {
                border-top: 1px solid color(lighter);
            }

            > .ticket__details {
                display: block;
                width: 75%;
                font-size: $textsize-body;

                .plus-padding-top{
                padding-top:4px;
                }
                &.wine-boat {
                    i {
                        position: relative;
                        width: 42px;
                        height: 42px;
                        display: inline-block;
                        padding: 0;
                        margin: 0 6px 0 0;
                        vertical-align: middle;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: color(primary);
                            border-radius: 100%;
                        }

                        &::after {
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url(../../img/icons/wine-glasses.svg);
                            background-size: 30px 30px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                }

                > .ticket__title {
                    font-size: $textsize-lead;
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            > .ticket__price {
                width: 25%;
                > h3 {
                    text-align: right;
                    font-size: $textsize-lead;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
            }

            > .ticket__description {
                width: 100%;
                padding-top: 12px;
            }
        }
    }
}