.checkout-title {
    font-size: 30px;
}

.margint-top-general {
    margin-top: 30px;
}

.checkout-section{


    .email-row{



    width:100%;

    @include mqMobileSmBootsrap{
        width:50%;
    }
    }
    .checkout-subtitle{
        font-size: 22px;
    }
    .title-bigger {
        @include mqMobile {
            font-size: 14px;
        }

        @include mqTablet {
            font-size: 16px;
        }

        @include mqMediumScreen {
            font-size: 18px;
        }
    }

    .icon-war {
        color: red;
        display: inline-flex;
        align-items: center;
        padding: 5px;

        svg {
        }
                }
    .warning-icon-input-first{

        @include mqMobile{
            top:45px;  
            right: 2%;
        }

        @include mqMediumScreen {
            top: 38px;
        }

        @include mqMobileSmBootsrap{
            right: 52%;
        }
        position: absolute;
        right: 52%;
        top: 35px;
    }

    .warning-icon-input-second{

        @include mqMobile{
            top:135px;
            right: 2%;  
        }

        @include mqMobileTiny {
            top: 135px;
        }
       
        @include mqMobileSmBootsrap{
            right: 52%;
        }

        position: absolute;
        right: 52%;
        top: 120px;
    }

    .form-control {
        border: 1px solid color(dark);
        -webkit-box-shadow: 0 0 10px color(dark);
        box-shadow: 0 0 1px color(dark);

        &:focus {
            box-shadow: none;
        }
    }

    .form-select {
        -webkit-box-shadow: 0 0 10px color(dark);
        box-shadow: 0 0 1px color(dark);
        border: 1px solid color(dark);

        &:focus {
            box-shadow: none;
            border-color: #eecf3b;
        }
    }

    .form-check {
        label {
            margin-top: 0;
        }
    }
    .form-check-input {
        &:checked {
            background-color: #eecf3b;
            border-color: color(dark);
            box-shadow: none;
        }
        &:focus {
            box-shadow: none;
        }
    }
    label {
        font-weight: 500;
        font-size: 13px;
        color: color(dark);


    @include mqMobileMedium{
        font-size: 13px;  
    }
    &.form-check-label{
    font-weight: 400;

        &.form-check-label {
            font-weight: 400;

            .files {
                cursor: pointer;
                font-weight: 700;
                text-decoration: underline;
                text-decoration-color: #eecf3b;
            }
        }
    }
}
    .star-sd-color {
        font-size: 17px;
        color: red;
    }
    .btn-wrapper {
        align-items: center;
    }
    .add-info {
        display: flex;
        border: 1px solid red;

        padding: 5px;
        .add-text {
            padding-top: 2px;
            padding-left: 5px;
            font-size: 14px;

            color: red;
        }
    }
    padding-top: 0 !important;
    .bolder-texts-general {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;

        @include mqMobile {
            font-size: 13px;
        }

        @include mqTablet {
            font-size: 15px;
        }

        @include mqMediumScreen {
            font-size: 17px;
        }
    }

    .table-title {
        h3 {
            color: white !important;
        }
    }

    .ticket-types__item {
        &.boxed--cruise-type {
            .boxed-content {
                overflow-x: initial;
            }
        }

        .parent-element {
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
        }

        .element {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .general-height-bigger {
            height: 80px;
        }

        .general-height-smaller {
            height: 30px;
        }
        h3,
        h6,
        p {
            margin-bottom: 0;
        }

        .general-orint-middle {
            text-align: center;
        }

        .general-orint-right {
            text-align: right;
        }

        .general-border-right-bottom {
            border-right: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
        }

        .general-border-left-bottom {
            border-left: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
        }

        .general-border-right {
            border-right: 1px solid #ececec;
        }

        .general-border-left {
            border-left: 1px solid #ececec;
        }

        .general-border-top {
            border-top: 1px solid #ececec;
        }

        .ticket-descripton-wrapper {
            display: flex;
            flex-direction: column;
            align-self: center;
            div {
                margin-bottom: 0.4rem;
                margin-left: 50px;
            }
        }
        .ticket-descripton {
            display: flex;
            flex-direction: rows;
            align-self: center;

            @include mqMobile {
                padding-left: 15px;
            }

            @include mqTablet {
                padding-left: 30px;
            }
            .cruise-type {
                font-weight: 700;
                font-size: 15px;
            }

            .cruise-type-desc {
                font-size: 13px;
                font-weight: 400;
            }

            h6 {
                margin-top: 0.2rem;
                margin-bottom: 0.2rem;
            }
        }
    }

.border-global-lighter{
    height: 70px;
    border: 1px solid #ececec;
    label{
    font-size:18px;
    letter-spacing: 1px;
    color:#c4c4c4;
    }
}
    .border-global-stronger {
        box-shadow: 0 0 0 1.5px color(dark);
        label {
            font-size: 18px;
            letter-spacing: 1px;
        }
    }

.radio-container{
    
    .form-check-label--otp{
line-height:17px;
    }
    .radio-wrapper{
        display: flex;
    flex-direction: row;
    align-self: center;
    line-height: 50px;
        padding: 10px;

        .radio-wrp{
        margin-left:-1em;
        }
        span{
            line-height:50px;
            margin-left: .5em;

            img{
                min-width: 100px;
                width: 100px;
            }
        }
        label{
            margin-left:.5em;
        display: flex;
        align-items: center;
        }

        input{

            &.g-top{
                top: 30%;
            }
        }
    }
    input{
        margin-top: 6px;
    }
   

        > div {
            margin-bottom: 10px;
            color: #c4c4c4;
            border-color: #c4c4c4;
        }
    }

    .additional-info {
        .additional-title {
            font-size: 13px;
            margin-bottom: 6px;
        }

        .additional-text {
            font-size: 13px;
            line-height: 165%;
        }
    }
    .back-btn{
        font-weight: 500;
        font-size: 16px;
        margin-left: 12px;
        letter-spacing: 1px;
    }


.sender{
    letter-spacing: 1px;
font-size:16px !important;
color:#c4c4c4;
border-color:#c4c4c4 !important;
min-width: 175px !important;
display: flex;
    justify-content: center;
    align-items: center;

svg{
    width: 0.505em;
    margin-left:10px;
}
}

.aszf > *{
padding-left:0;
padding-right:0;
}

    .left-btn {
        display: inline-flex;
        align-items: center;
        padding: 5px;

        .back-btn {
            font-weight: 400;
            font-size: 20px;
            margin-left: 10px;
        }
    }

    .sender {
        font-size: 16px !important;
        color: #c4c4c4;
        border-color: #c4c4c4 !important;
        min-width: 175px !important;

        svg {
            width: 0.505em;
        }
    }

    .aszf > * {
        padding-left: 0;
        padding-right: 0;
    }

    .aszf {
        margin: 0;
        margin-top: 30px;

        > * {
            padding-left: 0;
            padding-right: 0;
        }

        > div {
            margin-bottom: 0;
        }

        .first-col {
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-bottom: 0 !important;
        }

        .second-col {
            display: flex;
            justify-content: flex-start;
            margin-top: 10px;
            
            @include mqTablet {
                display: flex;
                justify-content: flex-end;
                margin-top: 0;
            }
        }
    }
}
