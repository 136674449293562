
.section--cart{
    padding-bottom: 4.42857143em;
}

.cartlist-container{
    padding: .92857143em;


    
    .table-cell__price__lighter{
        font-weight: 400 !important;
                }
    .table__ticket-type-col{
    text-align: center !important;
    }
    tfoot{
        border-top: 1px solid #ebebeb !important;
        display: block !important;
        @include mqTablet{
            display: table-footer-group !important;
        }
    }
tr{

td{
    padding: 12px 2px !important;

    @include mqMobileMedium{
        padding: 12px 10px !important;  
    }
&.btn-refresh-td{
    padding-right:0px;
    
}

&.cartlist__sum{
    font-size: 16px;
    font-weight: bolder;
}

&.cartlist__price{
    font-size: 16px;
    font-weight: normal;
}

ul{

&.box-table__list {
    li{
        display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: normal;
        font-size:13px !important;
        justify-content: center;
        @include mqTablet{
            justify-content: flex-start;
        }
    }

}
}
}
}

.cartlist__quantity{

    .numselector__number-section{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number-section__number{
        display: inline-block;
        margin: 0 22px;
        height: 42px;
        min-width: 32px;
        width: auto;
        border: 2px solid color(dark);
        border-radius: 4px;
        padding: 6px 6px;
        font-size: $textsize-body;
        font-weight: 700;
        text-align: center;

        @include mqTablet{
            margin: 0 12px;
        }
    }

    .number-section__plus,
    .number-section__minus{
        &:hover{
            background-color: color(primary);
        }
        cursor: pointer;
        display: inline-block;
        height: 35px;
        width: 35px;
        padding: 2px;
        border-radius: 100%;
        border: 2px solid color(dark);
        position: relative;

        @include mqTablet{
            height: 24px;
            width: 24px;
        }
        >svg{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 12px;
            width: 12px;
            padding: 0;
            margin: 0;
        }
    }
}
.cartlist__ticket{

    text-align: center !important;
@include mqTablet{
    text-align: left !important;
}
    .ticket__items{


        padding-left: 0;
        @include mqTablet{
            padding-left: 2rem;
        }
        li::before{
   
    @include mqTablet{
        background-color: inherit !important;
        content: "";
    background-color: black;
    width: 12%;
    height: 1px;
    max-width: 40px;
    min-width: 16px;
    margin-right: 12px;
    }
        }
        }

   

            .ticket__type{
                font-size: 16px;
    font-weight: bold;
    line-height: normal;
    padding: 0;
    margin: 0;

          
                margin-left:0 !important;

                text-align: center !important;
                @include mqTablet{
                    text-align: left !important;
                }

                @include mqSmallScreen{
                margin-left:20px !important;
                }
         
        }

        .ticket__date{
            font-size: 13px;
    line-height: normal;
    text-align: left;
            margin-left:0 !important;

            @include mqSmallScreen{
            margin-left:20px !important;
            }
        }
}

.btn-refresh-wrapper{

    justify-content: flex-start;
    padding-right: 35px;
display: flex;
    @include mqTablet{
        justify-content: flex-end; 
    }

    
    @include mqSmallScreen{
        padding-right:5px;
        }
.refresh-btn{
    min-width: 140px !important;
    font-size: 13px;
    margin-bottom:10px;
    margin-top:10px;
background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
max-width: 100%;
margin: auto auto;
    @include mqTablet{
        max-width: 250px;
        margin:0;
       margin-left: auto;
    }
    &:hover{
        background-color: color(primary);
        font-weight: 700;
    }
    svg{
        margin-left: 3px;
    }
}

}

}





.result-item{
justify-content: flex-end;
display: flex;

padding-top: 4.42857143em;

.boxed{
    padding:30px;}
    
.result-cost{
    font-weight: 600;
text-align: right;
font-size: 22px;
}

.result-text{
    font-weight: 600;
text-align: left;
font-size: 22px;
}
.result-btn-wrapper{
justify-content: flex-end;

.btn-container{
.btn{
    min-width: 190px !important;
}
}
}

.btn{
background-color: #eecf3b;

}

}

