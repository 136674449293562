th,
td {
    font-size: 1.25em;
}

th span {
    font-size: 0.8em;
    opacity: 0.4;
    font-weight: 400;
}

/*BOXED CONTENT TABLES*/
.boxed {
    .boxed-content__table {
        width: 100%;
        height: auto;
        border-collapse: collapse;

        &.table--inner-borders {
            thead > tr {
                border-bottom: 1px solid color(light);

                td:not(:last-of-type){
                    border-right: 1px solid color(light);
                }
            }
            tbody{
                tr{
                    &:not(:last-of-type){
                        border-bottom: 1px solid color(light);
                    }

                    td{
                        &:not(:last-of-type){
                            border-right: 1px solid color(light);
                        }
                    }
                }
            }
            tfoot{
                tr{
                    border-top: 1px solid color(light);
                    >td:not(:last-of-type){
                        border-right: 1px solid color(light);
                    }
                }
            }
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        tr > td {
            padding: 12px 20px;

            @include mqTablet {
                padding: 12px 40px;
            }
        }

        thead {
            tr > td {
                font-size: $textsize-body;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 1px;
                text-align: center;

                &.text-left {
                    text-align: left;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 6px 20px;

                    @include mqTablet {
                        padding: 12px 40px;
                    }

                    span,
                    div,
                    ul,
                    ul > li {
                        font-size: $textsize-body;
                    }
                }
            }
        }

        tfoot{
            tr{
                td{
                    padding: 6px 20px;

                    @include mqTablet{
                        padding: 12px 40px;
                    }
                }
            }
        }

        &.table--gridtable {
            @media screen and (max-width: 767px) {
                width: 100%;
                display: block;
                overflow: unset;

                &.thead--donthide {
                    thead {
                        visibility: visible;
                        display: table-header-group;
                    }
                }

                .hide-this {
                    display: none;
                    visibility: collapse;
                }

                thead,
                tbody,
                tfoot {
                    display: block;
                }

                thead {
                    visibility: collapse;
                    display: none;
                }

                tr {
                    display: grid;
                    width: 100;
                    height: auto !important;
                    grid-template-columns: auto;
                    padding: 20px;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid color(light);
                    }

                    td {
                        border: none !important;
                        width: 100% !important;
                    }
                }
            }
        }

        &.table--gridtable-cart {
            @media screen and (max-width: 991px) {
                width: 100%;
                display: block;
                overflow: unset;

                thead,
                tbody {
                    display: block;
                }

                thead {
                }

                tr {
                    display: grid;
                    width: 100;
                    height: auto !important;
                    grid-template-columns: auto;
                    padding: 20px !important;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid color(light);
                    }
                }
            }
        }
    }
}
