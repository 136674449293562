$colors:(
    // primary: #e7cc5e,
    primary: #eecf3b,
    accent: #fff,
    dark: #252525,
    dark-accent: #353331,
    black: #000,
    light: #ebebeb,
    lighter: #e7e7e6,

    red: #E65D3A

);

