// Variables
$menuItemPadding-big: 36px;
$menuItemPadding-medium: 32px;
$menuItemPadding-small: 24px;



.top-bar{
    padding: 5px 0;
background-color: black;
color:white;
.email-wrapper{
.flag-wrapper{
    display: flex;
    width: 60px;
}
    span{
letter-spacing: 1px;
font-size: 11px;
    } 
.top-bar-email-title{
font-weight: 100;
}

.top-bar-email{
    font-weight: 600;
}
}


.bottom-bar{

a{
text-transform: uppercase;
color:color(primary)
}

}
}
.nav--section {
    padding: 0 !important;
    z-index: 1000;
    width: 100%;
    
    top: 0;
    max-height: unset;

    // static navbar
    &.nav-static {
        position: relative;
        width: 100%;

        .nav-container {
            background-color: color(dark);
            border-bottom: solid 10px color(primary);

            .menu-container {
                background-color: color(dark);
                padding: 12px;
            }
        }
    }
    // end of static navbar

    &.nav-absolute-top {
        position: absolute;
        top: 0;
    }

    .bar,
    .logo {
        max-height: unset;

        &.mobile-logo{
        top:0;
        }
    }

    .nav-container {
        background-color: color(dark);
        width: 100%;
        z-index: 1000;
        transition: all 0.1s ease;
        display: block;

        .hamburger-toggle {
            .icon {
                cursor: pointer;
                position: relative;
                color: color(primary);
                opacity: 1;
                margin: 0;
                width: 1em;
                height: 100%;
                font-variant: normal;
                font-size: 36px;

                &::before {
                    margin: 0;
                    top: 0;
                    left: 0;
                    padding: 20px 0;

                }
            }
        }

        .logo--mobile {
            height: 100%;
            padding: 20px 0;
            > img {
                max-height: 40px;
            }
        }
        border-bottom: 4px solid color(primary);
        // STICKY MENU
        &.sticky {
            background-color: color(dark);
            border-bottom: 4px solid color(primary);
            max-height: unset;
            position: fixed;
            padding: 0;
            top: 0;

            .menu-container {
                padding: 12px 0;
                background-color: color(dark);

                .logo-container {
                    max-height: 80px;
                    display: none;

                    @include mqTablet {
                        margin-right: $menuItemPadding-small;
                    }

                    @include mqSmallScreen {
                        display: block;
                    }

                    > .logo {

                       
                      //  &::before {
                      //      background-image: url("../../img/logo/1x/kisfaludy_logo_compact_colored.png");
                          
                       //     margin-left: 2px;
                   //     }

                        @include mqSmallScreen {
                            max-height: 160px;
                        }
                    }
                }
            }

            @include mqSmallScreen {
                max-height: 160px;
            }
        }

        /*NAV -- MENU*/
        .menu-container {
            display: none;
            flex-direction: column;
            height: 100%;
            padding: 5px 0;
            font-size: $textsize-compact;
            background-color: color(dark);
            &.active {
                display: flex !important;
            }

            @include mqSmallScreen {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                align-content: center;
            }

            //Menu container
            > .menu {
                justify-content: flex-end;
                display: flex;
                flex-direction: column;
                height: 100%;
                margin: 0;
                padding: 0;
                width: 100%;

                &[class*=" menu--"] {
                    > li:not(:last-of-type) {
                        @include mqSmallScreen {
                            padding-right: $menuItemPadding-small;
                        }

                        @include mqMediumScreen {
                            padding-right: $menuItemPadding-medium;
                        }

                        @include mqLargeScreen {
                            padding-right: $menuItemPadding-big;
                        }
                    }
                }

                @include mqSmallScreen {
                    flex-direction: row;
                    align-items: center;
                }

                > li {
                    text-align: left;
                    padding-top: 1.6em;
                    font-weight: 100;
                    letter-spacing: 1px;
                    font-size: 16px;

                    @include mqSmallScreen {
                        text-align: center;
                        padding-top: 0;

                        a {
                            font-size: 0.9em;
                            &.active {
                                text-decoration: underline;
                            }
                            &:hover{
                            text-decoration: none;
                            }
                        }
                    }
&.cart-wrapper{
display: flex;
align-items: center;

.cart-icon-container{
display: flex;
width: 50px;

.cart-counter{
    width: 17px;
    height: 17px;
color:white;

    
    position: absolute;
    border:1px solid color(primary);
    border-radius:50%;
    background-color: black;
left:38px;
    @include mqSmallScreen{
        left:unset;
        right: 73px;
    }
    .cart-text{
        line-height: 15px;
    display: table-cell;
    font-size: 11px;
    width: 20px;
    text-align: center;
    padding-left: 2px;
    }
}
}
.cart-class{
    max-width: 35px;
    margin-right: 10px;
    max-height: 35px;
}
}

                    > a {
                        color: white;
                        text-transform: uppercase;
                        font-weight: 300;

                        &.highlight {
                            color: color(primary);
                        }
                    }

                    // Dropdown menu item
                    &.dropdown {
                        display: block;
                        position: relative;
                        cursor: pointer;

                        &.dropdown--active {
                            pointer-events: none;
                        }

                        a {
                            color: white;
                            pointer-events: all;
                        }

                        > a::after {
                            font-family: stack-interface;
                            content: "\e80c";
                            display: inline-block;
                            height: 100%;
                            padding-left: 6px;

                            @at-root .dropdown.dropdown--active > a::after {
                                content: "\e80a" !important;
                            }
                        }

                        > .dropdown__container {
                            display: none;

                            // Theme reset
                            position: relative;
                            left: 0 !important;

                            &::before {
                                content: none;
                            }

                            > .dropdown__item {
                                > a {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    color: white;
                                    font-size: inherit;
                                    text-transform: uppercase;
                                    width: min-content;
                                    padding-top: 1.6em;
                                    padding-left: 2em;
                                    vertical-align: middle;

                                    > .icon {
                                        max-height: 16px;
                                        max-width: unset;
                                        padding-right: 16px;
                                        opacity: 1;
                                    }
                                }
                            }

                            @include mqSmallScreen {
                                position: absolute;
                                z-index: 10;
                                pointer-events: unset;
                                padding-top: 0;
                                background-color: color(dark);
                                width: auto;
                                padding: 12px;
                                @include boxShadowSimple;

                                > .dropdown__item {
                                    &:not(:last-of-type) {
                                        padding-bottom: 6px;
                                    }

                                    > a {
                                        flex-flow: nowrap;
                                        align-content: center;
                                        flex: 1 auto;
                                        padding: 0;

                                        &:hover > a {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }

                        &.dropdown--active,
                        &:active,
                        &:focus {
                            > .dropdown__container {
                                display: block;
                            }
                        }
                    }
                }
            }

            > .logo-container {
                height: 65px;
                width: 150px;
                display: none;

                .logo.logo--center{
                   // display: flex;
                    justify-content: center;              
                }
                @include mqSmallScreen {
                    height: 100px;
                    width: 175px;
                    display: block;
                  
                }

                @include mqMediumScreen {
                    height: 100px;
                    width: 200px;
                 
                }

                .logo {
                    height: 100%;
                    width: 100%;
                    max-height: unset;
                    top: unset;

                  /*  &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                       // background-image: url("../../img/logo/1x/kisfaludy_logo_compact_colored.png");
                       // background-image: url("../../img/graphic_assets/animated-logo/kisfaludy-animated-logo2.svg");
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        margin-left: 1px;

                        @include mqMediumScreen {
                        //    background-image: url("../../img/logo/3x/kisfaludy_logo_multicolored-3x.png");
                       // background-image: url("../../img/graphic_assets/animated-logo/kisfaludy-animated-logo2.svg");
                        }
                    }*/
                    > a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        display: block;
                        z-index: 10;
                    }
                    img {
                        height: 100%;
                        display: none;
                    }
                }
            }
        }

        // --- GENERIC MENU FUNCTIONS --- /
        .inactive {
            pointer-events: none !important;
            opacity: 0.4;

            li,
            a {
                pointer-events: none !important;
            }
        }
    }
}

.sticky {
    section {
        &.nav--section {
            div {
                &.nav-container {
                    background-color: color(dark);
                    border-bottom: 4px solid color(primary);
                    max-height: unset;
                    position: fixed;
                    padding: 0;
                    top: 0;
                    .menu-container {
                        padding: 12px 0;

                        .logo-container {
                            max-height: 90px;
                            display: none;

                            @include mqTablet {
                               // margin: 0 $menuItemPadding-small;
                            }

                            @include mqSmallScreen {
                                display: block;
                            }

                            > .logo {
                                &::before {
                                   // background-image: url("../../img/logo/1x/kisfaludy_logo_compact_colored.png");
                                    margin-left: 2px;
                                }

                                @include mqSmallScreen {
                                    max-height: 160px;
                                }
                            }
                        }
                    }

                    @include mqSmallScreen {
                        max-height: 160px;
                    }
                }
            }
        }
    }
}

.logo-animated-container:hover{
    color:white;
    svg{
    #kisfaludy-log-anim-ci-v1{animation-play-state: running !important}}
}

.animated-logo-navbar{
    cursor:pointer;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
svg{
cursor:pointer;
}
}