.list {
    display: block;
    padding: 12px 0;

    font-size: $textsize-body;
}

.list-nopadding {
    padding: 0 !important;
}

.list > li:not(:first-of-type) {
    padding-top: 6px;
}

.list > li.text-center {
    text-align: center;
}

.list li > ul {
    padding-left: 2em;
}

/*-----LIST STYLES------*/

/*LIGHT STYLE*/

.list {
    &.list-style-light {
        > li {
            padding: 0 0 0 20px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: color(primary);
                top: 0.75em;
                left: 0;
            }
        }

        &.list-loose {
            li {
                padding-bottom: 10px !important;
            }
        }
    }

    &.list-style--elegant{
        >li{
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: left;

            &::before{
                width: 12%;
                min-width: 12px;
                max-width: 42px;
                height: 1px;
                background-color: color(dark);              
                margin-right: 12px;  
            }

            @include mqTablet{
                &::before{
                    content: "";
                    display: block;
                }
            }
        }
    }
}

/*LIGHT STYLE -- SUBLIST*/
.list.list-style-light .list-sublist {
    padding: 0;
}

.list.list-style-light .list-sublist > li {
    position: relative;
    padding: 0 0 0 45px;
}
.list.list-style-light .list-sublist > li:before {
    content: "";
    display: block;
    position: absolute;
    top: 0.8em;
    left: 0;
    width: 25px;
    height: 4px;
    background-color: #252525;
    opacity: 0.2;
}

/*Button list*/

.list.button-list {
    width: 100%;
    cursor: pointer;
}

.list.button-list > li {
    padding: 12px 16px;
    border: 1px solid #212023;
    border-radius: 10px;
    overflow: hidden;

    font-size: 1.2em;
    color: #212023;
}

@media (min-width: 768px) {
    .list {
        padding: 12px;
    }
}

.list.button-list > li:not(:first-of-type) {
    margin-top: 12px;
}

.list.button-list > li:focus,
.list.button-list > li:hover {
    background-color: color(primary);
}

.list.button-list.theme-dark > li:focus,
.list.button-list.theme-dark > li:hover,
.list.button-list.theme-dark > li.active {
    background-color: #252525;
    color: color(primary);
}

.simple-list li {
    padding: 0.1em 0 0.1em 1em;
    margin: 1em 0;
    line-height: 145%;
}
