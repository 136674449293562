html,
body {
    height: 100%;
   // overflow: hidden;
}




table,
tr,
td {
    padding: 0;
    margin: 0;
    border: none;
    border-spacing: 0;
}

img {
    padding: 0;
    margin: 0;
}

section.bg--secondary:not(.imagebg) + section:not(.imagebg):not([class*="bg-"]) {
    border-top: 1px solid #ebebeb;
}


.overflow-rule-before::-webkit-scrollbar{
    width: 0px;
    background: transparent;
}


.scrollable-content::-webkit-scrollbar {
    width:7px;
  }
  .scrollable-content::-webkit-scrollbar * {
    background:transparent;

  }
  .scrollable-content::-webkit-scrollbar-thumb {
    background:#eecf3b !important;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    min-height: 5px !important;
    height: 5px !important;

    height: 30px;
    width: 8px;
  }

  
  .scrollable-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  }

