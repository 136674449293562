hr {
    &.center {
        margin-left: auto;
        margin-right: auto;
    }

    &.compact {
        margin-top: $padding-smaller;
        margin-bottom: $padding-smaller;
    }

    &.medium {
        border-width: 0.25em;
        width: 35%;
        border-color: color(primary);
        opacity: 1 !important;
    }

    &.short {
        width: 6.5em;
        border-width: 0.45em;
        border-color: color(primary);

        &.dark {
            border-color: color(dark);
        }
    }
}