.section--ticket-details {
    .ticket-calendar__legend-wrapper {
        padding-left: 12px;

        span {
            font-size: 16px;
            display: block;

            &:not(:last-of-type) {
                margin-bottom: 6px;
            }

            &:before {
                content: "";
                display: inline-block;
                height: 16px;
                width: 16px;
                vertical-align: middle;
                margin-right: 12px;
            }

            &.legend-status-3 {
                &:before {
                    background-color: color(lighter);
                }
            }
            &.legend-status-2 {
                &:before {
                    background-color: color(red);
                }
            }
            &.legend-status-1 {
                &:before {
                    background-color: color(primary);
                }
            }
        }
    }

    .ticket-details__column {

        .ticket-num__section{
            &:not(:last-of-type){
                margin-bottom: $padding-small;
            }
        }

        hr{
            margin: 32px 0;
        }
        
        .ticket-num__wrapper {
            margin-top: 64px;
            width: 100%;

            @include mqSmallScreen {
                margin-top: 0;
            }

            h3 {
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 700;
            }
            .ticket-num__time-picker-wrapper {
                .ticket-num__time-picker {
                    margin-top: 32px;

                    .time-picker__arrow {
                        display: inline-block;
                        height: 12px;
                        position: absolute;
                        right: 16px;
                        top: 50%;

                        transform: translateY(-50%);
                    }

                    select {
                        background-color: white;
                        border-color: color(dark);
                        border-radius: 3px;
                        overflow: hidden;

                        option {
                            &:hover,
                            &:checked {
                                background-color: color(dark-accent);
                                color: white;
                            }
                        }
                    }
                }
            }

        }

        
        .ticket-num__numselector-wrapper {

            .numselector__price-section {
                .price-section__title{
                    h4{
                        font-size: 18px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }
            .numselector__number-section{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: $padding-small;

                @include mqSmallScreen{
                    justify-content: start;
                }

                .number-section__number{
                    display: inline-block;
                    margin: 0 12px;
                    height: 42px;
                    min-width: 32px;
                    width: auto;
                    border: 2px solid color(dark);
                    border-radius: 4px;
                    padding: 6px 6px;
                    font-size: $textsize-body;
                    font-weight: 700;
                    text-align: center;
                }

                .number-section__plus,
                .number-section__minus{
                    &:hover{
                        background-color: color(primary);
                    }
                    cursor: pointer;
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                    padding: 2px;
                    border-radius: 100%;
                    border: 2px solid color(dark);
                    position: relative;

                    >svg{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        height: 12px;
                        width: 12px;
                        padding: 0;
                        margin: 0;
                    }
                }

                .number-section__button{
                    display: flex;
                    align-items: center;
                    margin-left: $padding-tiny;

                    .btn__icon{                     
                        margin-left: 6px;

                        >svg{
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }

        .ticket-num__notes-section{
            h5{
                font-size: $textsize-body;
                font-weight: bold;
                margin-bottom: $padding-tiny;
            }

            p{
                font-size: $textsize-body;
                line-height: 165%;
            }
        }
    }
}
