
// Icons
.icon {
    max-width: 100%;
    max-height: 100%;
    opacity: 0.45;

    &.icon-inline-block {
        display: inline-block;
        vertical-align: middle;
    }

    &.opacity-100{
        opacity:1;
    }

    &.icon-xl{
        height: 38px;
    }

    &.icon-lg{
        height:32px;
    }

    &.icon-m {
        height: 20px;
    }

    &.icon-sm {
        height: 16px !important;
    }

    &.icon-xs {
        height: 8px !important;
    }

 

    /*Notification icons*/
 

    /*Flags*/
    &.flag-en {
        content: url("../../img/graphic_assets/flag_en.jpg");
    }

    &.flag-de {
        content: url("../../img/graphic_assets/flag_de.jpg");
    }

    &.flag-hu {
        content: url("../../img/graphic_assets/flag_hu.jpg");
    }

    // Generic icons
    &.left-arrow{
        content: "\2190";
    }
}

// Symbols
.symbol-container {
    display: block;
    width: 100%;
    height: 80px;
    text-align: center;
}

.symbol-1,
.symbol-star {
    max-height: 45px;
    max-width: 100%;
}

.symbol-1 {
    content: url("../../img/graphic_assets/symbol-01.svg");
    width: 220px;
}

.symbol-star {
    content: url("../../img/graphic_assets/symbol-star.svg");
    width: 50px;
}

[class*="symbol"] {
    display: inline-block;
}